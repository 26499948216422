(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict"; // legacy browser alert

function isLegacyIE() {
  var ua = navigator.userAgent;

  if (ua.indexOf("MSIE 6.0") != -1) {
    // Internet Explorer 6
    alert("ご利用のウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
  } else if (ua.indexOf("MSIE 7.0") != -1) {
    // Internet Explorer 7
    alert("ご利用のウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
  } else if (ua.indexOf("MSIE 8.0") != -1) {
    // Internet Explorer 8
    alert("ご利用のウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
  } else if (ua.indexOf("MSIE 9.0") != -1) {
    // Internet Explorer 9
    alert("ご利用のウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
  } else if (ua.indexOf("MSIE 10.0") != -1) {
    // Internet Explorer 10
    alert("ご利用のウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
  } else if (ua.indexOf("Trident/7.0") != -1) {
    // Internet Explorer 11
    alert("ご利用のウェブブラウザでは当サイトを正常に閲覧できません。最新のブラウザで再度アクセスしてください。");
  } else {
    return false;
  }
}

isLegacyIE(); // slide

var mySwiper = new Swiper('#slide', {
  loop: true,
  speed: 2000,
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
  autoplay: {
    delay: 4000
  }
});
$(function () {
  // drawer nav
  $("#hamburger").on('click', function () {
    $("#overlay").fadeIn();
    $("#drawer").addClass('active');
  });
  $("#overlay, #close").on('click', function () {
    $("#overlay").fadeOut();
    $("#drawer").removeClass('active');
  });
  $(window).on('resize', function () {
    $("#overlay").fadeOut();
    $("#drawer").removeClass('active');
  }); // dropdown menu

  $(".dropdown_trigger").each(function () {
    $(this).hover(function () {
      $(this).children('.dropdown_list').stop().slideDown();
    }, function () {
      $(this).children('.dropdown_list').stop().slideUp();
    });
  }); // scroll top

  var threshold = 200;
  $('#scroll_top').css('right', '-120px');
  $(window).on('scroll', function () {
    if ($(this).scrollTop() > threshold) {
      $('#scroll_top').stop().animate({
        'right': '16px'
      }, 200);
    } else {
      $('#scroll_top').stop().animate({
        'right': '-120px'
      }, 200);
    }
  });
  $('#scroll_top').on('click', function () {
    $('body,html').animate({
      scrollTop: 0
    }, 500);
  });
});

},{}]},{},[1]);
